<template>
  <v-container fluid>
    <TableViewComponent
      title="Table tarifs par établissement"
      @addItemEvent="onItem()"
      addItemLabel="ajouter un tarif pour un établissement"
      itemLabel="tarif"
      itemsLabel="tarifs"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer ce tarif ?"
      :pagination="false"
      :loading="loading"
      :vcols="{ xl: '6', lg: '8', md: '10', sm: '11', xs: '12' }"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { ProductPriceEstablishmentService } from "@/service/conf/product_price_establishment_service.js"
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { AvecProductsService } from "@/service/conf/avec_products_service.js";
import { PriceTypesService } from "@/service/conf/price_types_service.js"
import { PriceUnitsService } from "@/service/conf/price_units_service.js"

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";
import { numberWithMinTwoDecimal, numberToString } from "@/tools/number_tool.js";


export default {
  name: "TablePriceEstablishment",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /** le service de la vue */
      service: null,
      serviceEstablishment: null,
      serviceProduct: null,
      servicePriceTypes: null,
      servicePricesUnits: null,

      /**les éléments */
      entities: [],

      /** L'ensemble des noms digitaux des établissement */
      allEstablishmentName: null,
      /** L'ensemble des produits */
      allProducts: null,

      /** L'ensemble des types de tarifs */
      allPriceTypes: null,
      /** L'ensemble des unités de tarifs */
      allPriceUnits: null,
    };
  },
  methods: {
    onItem() {
      this.$router.push(routes.priceEstablishment.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];

        // Récupère la liste des noms digitaux des établissement
        this.allEstablishmentName = await this.serviceEstablishment.getDigitalNames();
        // Récupère la liste des produits (transmissions)
        this.allProducts = await this.serviceProduct.getAll();
        // Récupère la liste des type de tarifs
        this.allPriceTypes = await this.servicePriceTypes.getAll();
        // Récupère la liste des unité de tarifs
        this.allPriceUnits = await this.servicePricesUnits.getAll();

        this.entities = [];
        // Récupère tous les tarif de produit
        let entities = await this.service.getAll();

        entities.forEach((e) => {
          let entity = JSON.parse(JSON.stringify(e));

          // Récupération de l'établissement associé et mappage du nom
          let estab = this.allEstablishmentName.find((es) => es.id == entity.establishmentId);
          entity.establishmentName = estab.digitalName;

          // Récupération du produit associé et mappage du nom
          let prod = this.allProducts.find((p) => p.id == entity.productId);
          entity.productName = prod.digitalName;

          // Récupération du type de tarif et mappage du nom
          let typ = this.allPriceTypes.find((t) => t.id == entity.priceType);
          entity.priceTypeName = typ.label;

          // Récupération de l'unité de tarif et mappage du nom
          let unit = this.allPriceUnits.find((u) => u.id == entity.priceUnit);
          entity.priceUnitName = unit.label;

          // Formatting des nombres
          entity.prices = "";
          let i = 0;
          for (let amount of entity.amounts) {
            let price = numberWithMinTwoDecimal(amount);
            price = numberToString(price);

            if (i == 0) {
              entity.prices += `${price}`;
            } else {
              entity.prices += ` - ${price}`;              
            }
            i++;
          }


          //action de consultation
          entity.view = () => {
            this.$router.push(
              routes.priceEstablishment.view.root + "/" + entity.id);
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(
              routes.priceEstablishment.edit.root + "/" + entity.id
              + "?establishment=" + entity.establishmentId
            );
          };

          //action de suppression
          entity.delete = async () => {
            try {
              await this.service.delete(entity.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression du du tarif de produit : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.entities.push(entity);
        });

        // Tri des datas par nom d'établissement
        this.entities.sort(function(a, b) {
          return a.establishmentName.localeCompare(b.establishmentName);
        });
        // Tri des datas par product pour les établissement identiques
        this.entities.sort(function(a, b) {
          if (a.establishmentName == b.establishmentName) {
            return a.productName.localeCompare(b.productName);
          }
        });
        

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      return [
        {
          text: "Établissement",
          align: "start",
          sortable: true,
          value: "establishmentName",
          default: true,
        },
        {
          text: "Produit",
          align: "start",
          sortable: true,
          value: "productName",
          default: true,
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "priceTypeName",
          default: true,
        },
        {
          text: "Montant(s)",
          align: "start",
          sortable: true,
          value: "prices",
          default: true,
        },
        {
          text: "Unité",
          align: "start",
          sortable: true,
          value: "priceUnitName",
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditPriceEstablishment];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditPriceEstablishment, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadPriceEstablishment];
    },
  },
  mounted() {
    
    // Instanciation des services
    this.service = new ProductPriceEstablishmentService(this.$api.getProductPricesEstablishment());
    this.serviceEstablishment = new EstablishmentsService(this.$api);
    this.serviceProduct = new AvecProductsService(this.$api.getTransmissionsAvecProductsApi());
    this.servicePriceTypes = new PriceTypesService(this.$api.getPriceTypesApi());
    this.servicePricesUnits = new PriceUnitsService(this.$api.getPriceUnitsApi());

    this.load();
  },
};
</script>

<style>
</style>